import { Roles } from './Clases';
import {
  DatosPersonales,
  Moto,
  OcupacionPersona,
  OperacionFinanciera,
  Persona,
  TipoIdentificacionDTO,
} from './Persona';

export class RequestValIdent {
  codUsuario: number;
  correo: string;
  celular: string;
  sTipoDocumento: string;
  sDocumento: string;
}
export class RequestValIdentCodigo {
  codUsuario: number;
  correo: string;
  celular: string;
}
export class RequestValidCodigo {
  sCodigo: string;
  sCorreo: string;
  sCelular: string;
  sTipoDocumento: string;
  sDocumento: string;
}

export class RequestValidarPersona {
  tipoDocumento: string;
  documento: string;
}

export class RequestCupoMaxSolCred {
  sIdentificacion: string;
  sCodCredito: string;
  sPeriodicidad: string;
}

export class RequestSimuladorCredito {
  sValorMoto: string;
  sMonto: string;
  sNroCuota: string;
  sCodCredito: string;
  sIdentificacion: string;
  xsFormaPeriodicidad: string;
  fFechaSolicitud: string;
}

export class RequestEstadoCuentaPagos {
  pws_ident_cliente: string;
  pws_lin_cre: string;
  pws_num_radic: string;
}

export class ResponseLineaCredito {
  BRta: boolean;
  SMsj: string;
  ListLineaCredito: ArrayOfLineaCreditoDTO;
}

export class ArrayOfLineaCreditoDTO {
  LineaCreditoDTO: LineaCreditoDTO[];
}

export class LineaCreditoDTO {
  ICodigo: number;
  SDescripcion: string;
}
export class ListasCreditosDTO {
  ListLineaCredito:{
    LineaCreditoDTO:{
    }
  }
}

export class SolicitudCreditoDTO {
  CRequiereCodeudor: string;
  FFechaSolicitud: string;
  ICodPeriodo: number;
  ICodigo: number;
  IEstadoSolicitud: string;
  INumRadic: string;
  CNumRadic: string;
  IPersona: Persona;
  IPlazo: string;
  ITipoCredito: TipoCredito;
  NMontoSolicitado: number;
  NValorCuota: number;
  CPagareCygnus: string;
  NEstadoFirma: number;
  NValorTotalMoto: number;
}

export class DataPuntajeModel {
  NPuntaje: number;
  ISolCredito: number;
  NValorCuotaData: number;
  NValorIngresos: number;
  CBase64: string;
  COpcBase64: string;
  IPersona: number;
  cCapacidadPago: number;
}

export class ResponsePuntajeModel {
  /*ICodigo: number;
  ISolCredito: number;
  NPuntaje: number;*/
  NScoring: number;
  NValorCuotaData: number;
  // NValorIngresos: number;
  // CBase64: string;
  // COpcBase64: string;
}

/* export class TipoCredito{
  CEstado: string;
  CNombre: string;
  ICodigo: number;
}
 */
/* export class Persona{
  CApellidos: string;
  CEstado: string;
  CIdentificacion: string;
  CNombres: string;
  ICodigo: number;
  ITipoIdent: number;
}
 */
export class EstadoSolicitud {
  CDescripcion: string;
  ICodigo: number;
}

export class ResponseCupoMaximo {
  bRta: boolean;
  sMsj: string;
  sCupoMaximo: string;
  sPlazoMaximo: string;
  sTasaInteres: string;
}

export class LiquidacionAtr {
  n_num_cuo: string;
  f_fecha: string;
  n_sal_ini: string;
  n_capital: string;
  n_int_1: string;
  n_int_2: string;
  n_int_3: string;
  n_int_4: string;
  n_int_5: string;
  n_int_6: string;
  n_int_7: string;
  n_int_8: string;
  n_int_9: string;
  n_int_10: string;
  n_cap_tf: string;
  n_int_tf: string;
  n_total: string;
  n_sal_fin: string;
  n_int_nom1: string;
  n_int_nom2: string;
  n_int_nom3: string;
  n_int_nom4: string;
  n_int_nom5: string;
  n_int_nom6: string;
  n_int_nom7: string;
  n_int_nom8: string;
  n_int_nom9: string;
  n_int_nom10: string;
}

export class Res {
  Res_DetAvance: any;
}


export class ArrayOfLiquidacionAtr {
  LiquidacionAtr: LiquidacionAtr[];
}

// export class ArrayOfLiquidacionAtr2 {
//   Res: Res[];
// }

export class ResponseSimuladorCreditoAtr {
  bRta: boolean;
  sMsj: string;
  bdValorCuota: number;
  sTasa: string;
  rLiquidacion: ArrayOfLiquidacionAtr;
}

export class AperturaCuentaSolCred {
  ICodigo: number;
  ITipoIdent: number;
  CIdentificacion: string;
  CNombres: string;
  CApellidos: string;
  FFechaExpedicion: string;
  FFechaNacimiento: string;
  CDireccion: string;
  ICiudad: number;
  CTelefono: string;
  CTipoCuenta: string;
  ITipoCondicion: CondicionCuenta;
  ISolCredito: SolicitudCredito;
}

export class CondicionCuenta {
  ICodigo: number;
  CNombre: string;
  CDescripcion: string;
  CEstado: string;
}

export class SolicitudCredito {
  ICodigo: number;
  INumRadic: number;
  CNumRadic: string;
  //IcodCred: number;
  FFechaSolicitud: Date;
  NMontoSolicitado: number;
  IPlazo: number;
  NValorCuota: number;
  ICodPeriodo: number;
  CRequiereCodeudor: string;
  IEstadoSolicitud: EstadoSolicitud;
  ITipoCredito: TipoCredito;
  IPersona: Persona;
  INumeroCuenta: number;
  CBase64: string;
  CPagareDeceval: string;
  IEstadoFirma: number;
  ICodigoMoto: Moto;
  IPersonaVendedor: Persona;
  NValorTotalMoto:number;
}

export class TipoDesembolso {
  ICodigo: number;
  CDescripcion: string;
}

export class TipoGenerico {
  ICodigo: number;
  CDescripcion: string;
}

export class InformacionDesembolso {
  ICodigo: number;
  ITipoDesembolso: TipoDesembolso;
  CNumCuenta: string;
  IEntidadFinanciera: string;
  ITipoCuenta: number;
  CNombresTitular: string;
  ITipoIdent: number;
  CIdentificacion: string;
  ISolCredito: SolicitudCredito;
}

export class RequestInformacionDesembolso {
  ICodigo: number;
  CNumCuenta: string;
  IEntidadFinanciera: string;
  ITipoCuenta: number;
  CNombresTitular: string;
  ITipoIdent: number;
  CIdentificacion: string;
  ISolCredito: SolicitudCredito;
  ITipoDesembolso: TipoDesembolso;
}
export class ResponseInformacionDesembolso {
  iCodigo: number;
  cNumCuenta: string;
  iEntidadFinanciera: string;
  iTipoCuenta: number;
  cNombresTitular: string;
  iTipoIdent: number;
  cIdentificacion: string;
  iSolCredito: SolicitudCredito;
  iTipoDesembolso: TipoDesembolso;
}

export class ResponseAperturaCuentaSolCred {
  iCodigo: number;
  iTipoIdent: number;
  cIdentificacion: string;
  cNombres: string;
  cApellidos: string;
  fFechaExpedicion: Date;
  fFechaNacimiento: Date;
  cDireccion: string;
  iCiudad: number;
  cTelefono: string;
  cTipoCuenta: string;
  iTipoCondicion: CondicionCuenta;
  iSolCredito: SolicitudCredito;
  beneficiarioAperturaCtaList: BeneficiarioAperturaCta[];
}



export class ResponseEstadoCreditoPagos {
  pIdentCliente: string;
  pLinCre: string;
  pNumRadic: string;
  bRta: boolean;
  sMsj: string;
  respuestaWs: any;

}

export class CiudadDTO {
  ICodigo: number;
  CCiudad: string;
  INivel: string;
  IIndNal: string;
  IIndInternal: string;
  IUbicacion: string;
  ICiudadDeceval: string;
  IDepartamento: DepartamentoDTO;
}

export class DepartamentoDTO {
  ICodigo: number;
  CDepartamento: string;
  ICodDepDeceval: string;
}


export class ResponseCiudad {
  BRta: boolean;
  SMsjConsulta: string;
  listCiudades: ArrayOfCiudadDTO;
}

export class ArrayOfCiudadDTO {
  CiudadDTO: CiudadDTO[];
}

export class RequestDatosPersona {
  datosPersonales: DatosPersonales;
  ocupacionPersona: OcupacionPersona;
  operacionFinanciera: OperacionFinanciera;
  informacionDesembolso: InformacionDesembolso;
  sInformacionDesembolso: string;
  sNumRadicSolCred: string;
}

export class RequestPlanPagos {
  datosPersonales: DatosPersonales;
  ocupacionPersona: OcupacionPersona;
  operacionFinanciera: OperacionFinanciera;
  informacionDesembolso: InformacionDesembolso;
  sInformacionDesembolso: string;
  sNumRadicSolCred: string;
}

export class ResponseNuevaSolicitudCredito {
  bRta: boolean;
  sMsj: string;
  solicitudCredito: SolicitudCredito;
}

export class TipoCredito {
  ICodigo: number;
  CNombre: string;
  CEstado: string;
}

export class requestRequsitosSolCred {
  idTipoReq: number;
  idTipoCred: number;
}

export class TipoRequisito {
  ICodigo: number;
  IDescripcion: string;
  CEstado: string;
}

export class RequisitosSolCred {
  ICodigo: number;
  CNombre: string;
  CDescripcion: string;
  CEstructura: string;
  CEstado: string;
  ITipoCredito: TipoCredito;
  ITipoRequisito: TipoRequisito;
}

export class DocumentoSolCred {
  ICodigo: number;
  CBase64: string;
  CNombreDocumento: string;
  CTipoArchivo: string;
  IRequisito: RequisitosSolCred;
  ISolCredito: SolicitudCredito;
  IPersona: number;
}

export class TipoAutorizacionSolCred {
  ICodigo: number;
  CDescripcion: string;
  CNombre: string;
  CEstado: string;
}

export class AutorizacionSolCredito {
  ICodigo: number;
  CAutorizo: string;
  CIp: string;
  CNavegador: string;
  FFechaSistema: Date;
  ISolCredito: SolicitudCredito;
  ITipoAutorizacion: TipoAutorizacionSolCred;
}

export class ResponseTiposAutorizacionesSolCred {
  bRta: boolean;
  sMsj: string;
  listTipoAutorizacion: TipoAutorizacionSolCred[];
}

export class ResponseSolCredito {
  bRta: boolean;
  sMsj: string;
  solicitudCredito: SolicitudCredito;
  //temporal
  listaSolicitud: SolicitudCredito[];
}

export class ResponseDocSolCred {
  bRta: boolean;
  sMsj: string;
  listDocs: DocumentoSolCred[];
}

export class RequestDatosPersonales {
  codPersona: string;
  idSolCred: string;
}

export class RequestCambiarEstadoSolCred {
  lCodSolCredito: number;
  lCodEstado: number;
  lCodUsuario: number;
  sObservacion: string;
  cNumRadic: string;
  cPagareCygnus: string;
  cPagareDeceval: string;
  nCodigoMotivo: number;
}

export class ResponseSolicitudDiligenciada {
  bRta: boolean;
  sMsj: string;
  solicitudCredito: SolicitudCredito;
}

export class RequestValidarLinea {
  lCodPersona: number;
  sCodLinea: string;
}

export class BeneficiarioAperturaCta {
  ICodigo: number;
  CNombres: string;
  CApellidos: string;
  ICiudad: number;
  CTelefono: string;
  NPorcentaje: number;
  IAperturaCuentaSolCred: AperturaCuentaSolCred;
}

export class RequestAperturaCuenta { }

export class DocumentoModel {
  ICodigo: number;
  CNombre: string;
  CBase64: string;
  CTipoArchivo: string;
  ISolCredito: SolicitudCredito;
}


//#region Clases Nuevas
export class ValoresInicales {
  NCoutainicial: number;
  Nplazo: number;
  NValorFinanciar: number;
  NValorCuota: number;
  NLineaCredito: number;
}

export class requestValorCuota {
  sValorMoto:number;
  xCredito:number;
  sMonto: number;
  sNroCuota: number;
  sCodCredito: number;
  sIdentificacion: string;
  xsFormaPeriodicidad: number
}
//#endregion

//#listaSolicitudes
export class solicitudModel {
  ICodigo: number;
  CAsociado: string;
  CCiudad: string;
  CEstadoSolicitud: string;
  NMontoSolicitado: number;
  CLineaCredito: string;
  FFechaSolicitud: string;
  CPlazos: string;
}

export class referidosModel {
  ITipoReferencia: TipoGenerico;
  ICodigo: number;
  CParentesco: string;
  CNombres: string;
  CNumCelular: string;
  NConfirmar: boolean;
  CTelefono: number;
  NConfirmacion: number;
  CApellidos: string;
  ICodCygnus: number;
  CObservaciones: string;
  IPersona: number;
}


export class ResponseDatosDesembolso {
  CBase64: string;
  CDocumento: number;
  CDescripcion: string;
  CEntidadFin: string;
  CNumcuenta: number;
  ICodigo: string;
  INumRadicado: string;
  ITipocuenta: number;
}

export class RequestCygnus {
  R_Val_cuota: string;
  R_numDesti: string;
  R_numPagare: string;
  R_numRadic: string;
  R_s_mensaje: string;
  type: string;
}
//#listaSolicitudes

export class UsuariosDTO {
  CEstado: string;
  ICodigo: string;
  IPerfil: Roles;
  IPersona: Persona;
  cCodMensaje: string;
}

export class ReporteDTO {
  cantidad: number;
  fechaFin: Date;
  fechaIniciio: Date;
  listaSolicitud: SolicitudCredito[];
  venta: number;
}

export class PersonaUsuarioDTO{
  CApellidos:string;
  CEstado:string;
  CIdentificacion:string;
  CNombres:string;
  ICodigo:number;
}
