import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RequestLogin } from '../../model/Clases';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public url = environment.URL_SERVICE + "Login";
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": "Bearer" + sessionStorage.getItem("token")
    }
  );

  constructor(private httpClient: HttpClient) { }

  loginEmpleado(request: RequestLogin) {
    let path = `${this.url}/LoginPersona`;
    let requestNuevo = new RequestLogin();
    requestNuevo.tipoDocumento = request.tipoDocumento;
    requestNuevo.tipoPerfil = request.tipoPerfil;
    requestNuevo.numDocumento = btoa(request.numDocumento);
    requestNuevo.password = btoa(request.password);
    requestNuevo.sNavegador = '';
    requestNuevo.iTipoCanal = 1;
    this.headers = this.headers.set('Authorization', 'Bearer');
    return this.httpClient.post<any>(path, JSON.stringify(requestNuevo) ,{headers: this.headers});
  }

  getIp() {
    return this.httpClient.get("https://api.ipify.org/?format=json");
  }

  loginCliente(requestValidarPersona: any) {
    let url =  `${environment.URL_SERVICE}WSRestClienteMY/validarCliente`;
    return this.httpClient.post(url, requestValidarPersona, { headers: this.headers, });
  }
}
